import './App.css';
import HeaderApp from '../components/header';
import PageContent from '../components/content';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BasePage from './BasePage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function Home() {
  return BasePage('Welcome to DevHelper!',
    <div style={{textAlign: "justify", margin: "2em"}}>
      <br/>
      <div style={{marginLeft: "10px"}}>
      <br/>
      Welcome to our website, designed specifically to help new web developers navigate the vast and ever-changing world of web development. As a new web developer, it can be overwhelming to know where to start, what resources to use, and how to keep up with the latest technologies and best practices. That's where we come in.
      <br/>
      <br/>
      Our website is packed with useful information, tips, and resources to help you on your journey to becoming a skilled web developer. Whether you're just starting out, or looking to improve your skills, we've got you covered. From tutorials on HTML, CSS, and JavaScript, to tips on optimizing website performance, to advice on landing your first job in web development, we've got everything you need to succeed.
      <br/>
      <br/>
      We know that the journey to becoming a web developer can be challenging, but with our help, you'll be able to overcome any obstacles that come your way. So, let's get started and take your first steps towards becoming a successful web developer!
      <br/>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default Home;
