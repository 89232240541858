import './App.css';
import * as React from 'react';
import HeaderApp from '../components/header';
import PageContent from '../components/content';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BasePage from './BasePage';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

//const PageContentRef = PageContent({ pageTitle: "Teste", PageContent: Home});

function Encoders() {

    const [encodeType, setEncode] = React.useState('');
    const [inputValue, setInput] = React.useState('');
    const [outputValue, setOutput] = React.useState('');

    const handleChange = (event) => {
        setEncode(event.target.value);
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleOutputChange = (event) => {
        setOutput(event.target.value);
    };

    function base64_encode(s) {      
        return btoa(unescape(encodeURIComponent(s)));
    }
    function base64_decode(s) {      
        return decodeURIComponent(escape(atob(s)));
    }

    function url_encode(s) {
        return encodeURI(s)
    }

    function url_decode(s) {
        return decodeURI(s)
    }


    const encodeString = (event) => {
        if (encodeType === 'strToBase64') {
            setOutput(base64_encode(inputValue))
        } else if (encodeType === 'base64ToStr') {
            setOutput(base64_decode(inputValue))
        } else if (encodeType === 'urlEncode') {
            setOutput(url_encode(inputValue))
        } else if (encodeType === 'urlDecode') {
            setOutput(url_decode(inputValue))
        } else {
            setOutput('')
        }
    }

    return BasePage('Encoders & Decoders',
        <div style={{textAlign: "justify", margin: "2em"}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={encodeType}
                    label="Encode type"
                    onChange={handleChange}
                >
                    <MenuItem value={'strToBase64'}>String to base64</MenuItem>
                    <MenuItem value={'base64ToStr'}>base64 to string</MenuItem>
                    <MenuItem value={'urlEncode'}>URL Encode</MenuItem>
                    <MenuItem value={'urlDecode'}>URL Decode</MenuItem>
                </Select>
                <TextField fullWidth label="Input" onChange={handleInputChange} value={inputValue} id="inputText" multiline rows={10} />
                <br />
                <Button variant="outlined" onClick={encodeString}>Process</Button>
                <br />
                    <TextField fullWidth label="Output" id="outputText" onChange={handleOutputChange} value={outputValue} multiline rows={10} />
            </FormControl>

        </div>
    );
}

export default Encoders;
