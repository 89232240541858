import './App.css';
import BasePage from './BasePage';

function Newsletter() {
  return BasePage('Newsletter',
    <div className="App" style={{textAlign: "justify", margin: "2em"}}>
        There are many great newsletters for software development, but here are a few that are widely regarded as some of the most important:
<br/>
<ol>
  <li>
    Software Lead Weekly: A weekly newsletter that covers a wide range of topics related to software development, including leadership, management, and technical topics.
  </li><br/>
  
  <li>
    JavaScript Weekly: A weekly newsletter that covers the latest news and trends in the world of JavaScript, including frameworks, libraries, and tools.
  </li><br/>
  <li>
    The Daily Dev: A daily newsletter that provides a curated selection of articles and resources related to software development, covering topics such as web development, programming, and data science.
  </li><br/>
  <li>
    Hacker Newsletter: A weekly newsletter that features the latest news and trends in technology, including software development, startups, and entrepreneurship.
  </li><br/>
  <li>
    GitHub's Trending Repositories: A weekly newsletter that highlights the most popular and trending repositories on GitHub, providing insights into the latest technologies and projects in software development.
  </li><br/>
  <li>
    Python Weekly: A weekly newsletter that covers the latest news and trends in the world of Python, including libraries, frameworks, and tools.
  </li><br/>
  <li>
    DevOps'ish: A weekly newsletter that covers the latest news and trends in the world of DevOps, including cloud computing, automation, and containerization.
  </li><br/>
</ol>
<br/>
By subscribing to these newsletters, software developers can stay up-to-date on the latest trends, tools, and best practices in the industry.
<br/>
<br/>
    </div>
  );
}

export default Newsletter;
