import './App.css';
import HeaderApp from '../components/header';
import PageContent from '../components/content';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

//const PageContentRef = PageContent({ pageTitle: "Teste", PageContent: Home});

function BasePage(title, content) {
  return (
    <div className="App">
      <HeaderApp ></HeaderApp>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <PageContent pageTitle={title} pageContentComponent={content}>
        </PageContent>
      </ThemeProvider>
    </div>
  );
}

export default BasePage;
