import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home'
import WebDev from './pages/webDev'
import Encoders from './pages/Encoders'
import ErrorPage from './pages/ErrorPage'
import Newsletter from './pages/Newsletter';
import MachineLearning from './pages/MachineLearning';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/webdev",
    element: <WebDev></WebDev>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/encoders",
    element: <Encoders></Encoders>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/newsletter",
    element: <Newsletter></Newsletter>,
    errorElement: <ErrorPage></ErrorPage>
  },
  {
    path: "/ml",
    element: <MachineLearning></MachineLearning>,
    errorElement: <ErrorPage></ErrorPage>
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
