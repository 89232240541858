import './App.css';
import BasePage from './BasePage';

function WebDev() {
  return BasePage('Web Development',
    <div className="App" style={{textAlign: "justify", margin: "2em"}}>
      <br/>
        Web development encompasses a wide range of topics, but some of the most important areas to focus on include:
        <br/>
        <div >
          <ol>
        <li>
        HTML, CSS, and JavaScript: These are the core technologies used to create websites. HTML provides the structure and content of a webpage, CSS adds style and design elements, and JavaScript adds interactivity and dynamic functionality.
        <br/>
        </li>
        <li>
        Responsive Design: With the increasing use of mobile devices, it's essential to ensure that websites are optimized for different screen sizes and resolutions. Responsive design techniques allow websites to adapt and provide a seamless user experience on any device.
        <br/>
        </li>

        <li>
        Web Accessibility: It's important to create websites that are accessible to all users, including those with disabilities. This includes using proper markup, providing alternative text for images, and ensuring keyboard accessibility.
        <br/>
        </li>

        <li>
        Website Performance: Website speed and performance are crucial for providing a good user experience. Optimizing website performance involves minimizing file sizes, optimizing images, and leveraging caching and other techniques.
        <br/>
        </li>

        <li>
        Search Engine Optimization (SEO): SEO is the process of optimizing a website to improve its visibility and ranking in search engines. This involves keyword research, optimizing on-page elements, and building backlinks.
        <br/>
        </li>

        <li>
        Content Management Systems (CMS): A CMS allows non-technical users to create and manage website content without the need for coding knowledge. Popular CMS platforms include WordPress, Drupal, and Joomla.
        <br/>
        </li>
        </ol>
        By focusing on these important areas, web developers can create effective and engaging websites that are accessible, performant, and optimized for search engines.
        <br/>
        </div>

        <br/>
        <br/>
    </div>
  );
}

export default WebDev;
